import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import kurentoUtils from 'kurento-utils';
import { IParticipant, useStores } from 'src/models';
import { toJS } from 'mobx';
/**
 * ## Participant 설명
 *
 */

type Props = {
  participant: IParticipant;
  sendMessage: (message: any) => void;
  onConnectionstatechange: (peerConnection: any, refresh?:VoidFunction, participantCUID?:string, userName?:string) => void;
  setPeer?: (cuid: string, uid: string, peer: any, reset: any) => void;
  screenStream: any;
  videoWidth?:any;
  videoHeight?:any;
};
let peer: any;
export const ScreenParticipant = observer(
  ({
    participant,
    sendMessage,
    onConnectionstatechange,
    setPeer,
    screenStream,
    videoWidth,
    videoHeight
  }: Props) => {
    const rootStore = useStores();
    const { pushAlarmStore } = rootStore;
    const [stream, setStream] = useState<any>();
    const videoRef = useRef<any>(); // createRef<HTMLVideoElement>();
    let constraints = {
      audio: {
        // echoCancellation: true,
        // noiseSuppression: true,
        sampleRate: pushAlarmStore.getSampleRateData() ? pushAlarmStore.getSampleRateData() : 48000,
        echoCancellation: {exact: true},
        autoGainControl: {exact :false},
        noiseSuppression: {exact: false}, 
        highpassFilter: {exact :false},
      },
      video: {
        width: 1280,
        framerate: 30,
      },
    };

    const myUID = sessionStorage.getItem('myUID');
    const myCUID = sessionStorage.getItem('myCUID');

    const refresh = (_participantCUID?:string) => {

      if(_participantCUID){
        if(_participantCUID !== myCUID){
          console.log('재수신 : ', participant.userName, _participantCUID)
          onRecive();
          return;
        }
      }

      if (participant.stream?.activities.length === 0) {
        screenStream.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
      const msg = {
        type: 'kurento',
        id: 'broadcastRestarted',
        uid: participant.uid,
      };
      sendMessage(msg);
    };

    const screen = (screenStream: any) => {
      if (screenStream !== undefined && screenStream !== 'reset') {
        setStream((stream: any) => screenStream);
        onSend(screenStream);
      }
    }

    useEffect(() => {
      if (myCUID === participant.cuid) {
        refresh();
      } else {
        onRecive();
      }
    }, [participant.uid, participant.stream?.activities.length]);

    useEffect(() => {
      if (screenStream) {
        screen(screenStream);
      }
    }, [screenStream]);

    const onSend = (state: any) => {

      const videoElement = videoRef.current;
      if (!videoElement) {
        setTimeout(() => {
          onSend(state);
        }, 100);
        return;
      }

      if (videoElement) {
        videoElement.srcObject = state;
      }

      const opt: any = {
        localVideo: videoElement,
        videoStream: state,
        mediaConstraints: constraints,
        onicecandidate: onIceCandidate,
      }
      if(participant.iceServers){
        opt.configuration = {
          iceServers: toJS(participant.iceServers)
        }
        opt.iceServers = toJS(participant.iceServers);
      }

      peer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(
        opt,
        (error) => {
          if (error) {
            return console.error(error);
          }
          peer.generateOffer(offerToReceiveVideo);
        },
      );
      setPeer && setPeer(participant.cuid, participant.uid, peer, "reset");
      onConnectionstatechange(peer.peerConnection, refresh, participant.cuid, participant.userName);
    };

    const onRecive = () => {

      const videoElement = videoRef.current;
      if (!videoElement) {
        setTimeout(() => {
          onRecive();
        }, 100);
        console.log('return videoRef.current onrecive');
        return;
      }

      const opt: any = {
        remoteVideo: videoElement,
        mediaConstraints: constraints,
        onicecandidate: onIceCandidate,
      }
      if(participant.iceServers){
        opt.configuration = {
          iceServers: toJS(participant.iceServers)
        }
        opt.iceServers = toJS(participant.iceServers);
      }

      peer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(
        opt,
        (error) => {
          if (error) {
            return console.error('err', error);
          }

        },
      );
      peer.generateOffer(offerToReceiveVideo);
      setPeer && setPeer(participant.cuid, participant.uid, peer, "reset");
      // onConnectionstatechange(peer.peerConnection);
      onConnectionstatechange(peer.peerConnection, refresh, participant.cuid, participant.userName);
      const play = videoElement.play();
      if (play !== undefined) {
        play.then(() => {
          videoElement.play()
        })
          .catch((error: any) => {
            console.log('error', error);
          });
      }
    };

    const offerToReceiveVideo = function (error: any, offerSdp: any) {
      if (error) {
        return console.error('sdp offer error');
      }
      let msg: any = {
        type: 'kurento',
        id: 'broadcastStarted',
        uid: participant.uid,
        sdpOffer: offerSdp,
      };
      if (myCUID !== participant.cuid) {
        msg = {
          type: 'kurento',
          id: 'addListener',
          sender: participant.uid,
          sdpOffer: offerSdp,
        };
      }
      sendMessage(msg);
    };

    const onIceCandidate = (candidate: any, wp: any) => {
      const message = {
        type: 'kurento',
        id: 'onIceCandidate',
        candidate: candidate,
        uid: participant.uid,
        luid: myUID !== participant.uid ? myCUID : participant.uid,
      };
      sendMessage(message);
    };
    
    
    return (
      <>
        <video
          ref={videoRef}
          id={'video-' + participant.uid}
          autoPlay={true}
          controls={false}
          playsInline
          style={{
            width: videoWidth,
            height: videoHeight,
          }}
        />
      </>
    );
  },
);

export default ScreenParticipant;
