import { WebRtcPeer } from 'kurento-utils';
import {
  IAnyModelType,
  IAnyType,
  Instance,
  ModelPrimitive,
  SnapshotOut,
  types,
} from 'mobx-state-tree';
import { Candidate, createCandidate, ICandidate } from '../candidate/Candidate';
import { createClient } from '../client/Client';
import { withSetPropAction } from '../extensions/withSetPropAction';
import { createStream } from '../stream/Stream';
import { type } from 'os';

/**
 * # Participant
 *
 * Participant을 설명하세요.
 */
// const myWebRtcPeer: ModelPrimitive | IAnyType = WebRtcPeer as ModelPrimitive | IAnyType;

export const IceServer = types
  .model('IceServer')
  .props({
    credential: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    urls: types.maybeNull(types.array(types.string)),
  });

export const Participant = types
  .model('Participant')
  // --------------------------------------------------------------------------
  .props({
    id: types.optional(types.string, ''),
    uid: types.optional(types.string, ''),
    cuid: types.optional(types.string, ''),
    sessionId: types.optional(types.string, ''),
    client: types.maybeNull(createClient()),
    stream: types.maybeNull(createStream()),
    sdpAnswer: types.maybeNull(types.string),
    // existings: types.optional(types.array(types.late((): IAnyModelType => Participant)), []),
    isLive: types.optional(types.boolean, true),
    exist: types.optional(types.boolean, false),
    recive: types.optional(types.boolean, false),
    candidate: types.maybeNull(Candidate),
    updated: types.optional(types.number, 0),
    userName: types.optional(types.string, ''),
    rights: types.optional(types.array(types.string), []),
    authCd: types.optional(types.string, ''),
    micLevel: types.optional(types.number, 0.5),
    volLeve: types.optional(types.number, 0.5),
    updateData: types.optional(types.number, 0),
    speaker: types.optional(types.number, 0),
    iceServers: types.maybeNull(types.array(IceServer))
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model participant --store
  .actions(withSetPropAction)
  // .actions((self) => ({

  //   setProps: (props: { [key: string]: any }) => {
  //     const newProps = { ...self, ...props } as IParticipant;
  //     (Object.keys(newProps) as TParticipantKeys[]).forEach((key) => {
  //       // @ts-ignore
  //       self[key] = newProps[key];
  //     });
  //   },

  // }))
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IParticipant;
      (Object.keys(newProps) as TParticipantKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TParticipant = Instance<typeof Participant>;
type TParticipantSnapshot = SnapshotOut<typeof Participant>;

export interface IParticipant extends TParticipant {}
export type TParticipantKeys = keyof TParticipantSnapshot & string;
export interface IParticipantSnapshot extends TParticipantSnapshot {}
export const createParticipant = () => types.optional(Participant, {} as TParticipant);
