import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, useState } from 'react';
import { useStores } from '../../../models/root-store/root-store-context';
import {
  Box,
  Toolbar,
  useTheme,
  IconButton,
  Typography,
  Button,
  Paper,
  Drawer,
  ListItemText,
  Dialog,
  Menu,
  Tooltip,
  MenuItem,
} from '@mui/material';
import { Stack } from '@mui/system';
import { HEADER } from 'src/config-global';
import { ReactComponent as IcoRoomInfo } from 'src/assets/icons/ico-room-info.svg';
import Iconify from 'src/components/iconify/Iconify';
import Footer from '../meeting/footer/Footer';
import useResponsive from 'src/hooks/useResponsive';
import ConferenceInvite from '../ConferenceInvite';
import Sidebar from '../sidebar/Sidebar';
import { IParticipant } from 'src/models/participant/Participant';

import MobileParticipant from 'src/screens/home/participant/MobileParticipant';
import { useLocales } from 'src/locales';
import { IMeetingMemberSnapshot } from 'src/models';
import MTime from './MTime';
// Import Swiper React components
import { SwiperSlide, Swiper } from 'swiper/react';
import SwiperCore from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './SwiperCss.css';
// import required modules
import { Pagination } from 'swiper/modules';
import Logout from '@mui/icons-material/Logout';
import CAlert from 'src/components/CAlert';
/**
 * ## MeetingM 설명
 *
 */
export const MeetingM = observer((props: any) => {
  const rootStore = useStores();
  const { participantStore, conferenceStore } = rootStore;
  const theme = useTheme();
  const { translate } = useLocales();
  const [viewSummary, setViewSummary] = useState(false);
  const [openInvite, setOpenInvite] = useState(false);
  const [type, setType] = useState(false);
  const isDownMd = useResponsive('down', 'md');
  const isDownSm = useResponsive('down', 'sm');
  const [mode, setMode] = useState(true);
  const [camDisabled, setCamDisabled] = useState(false);

  const [mMode, setMmode] = useState(0);
  const [mobileFullWidth, setMobileFullWidth] = useState(props.MobileFullWidth);
  const [mobileFullScreen, setMobileFullScreen] = useState(props.MobileFullScreen);
  const [margin, setMargin] = useState(0);
  const [swiper, setSwiper] = useState<SwiperCore>();
  const [mobileChangeSwiper, setMobileChangeSwiper] = useState(0);
  const [swiperSpeed, setSwiperSpeed] = useState(300);

  useEffect(() => {
    if (type) {
      let SwiperUn = document.getElementsByClassName('swiper-wrapper')[0] as unknown;
      let SwiperStyle = SwiperUn as HTMLElement;
      SwiperStyle.style.display = 'flex';
    }
  }, [type]);

  useEffect(() => {
    if (participantStore.participants.length > 1) {
      setType(true);
      setMobileFullWidth(props.MobileFullWidth / 4);
      setMobileFullScreen(props.MobileFullScreen / 4);
      setMargin(2);
    } else {
      setType(false);
      setMobileFullWidth(props.MobileFullWidth);
      setMobileFullScreen(props.MobileFullScreen + 7);
      setMargin(0);
    }
    let VideoVol = document.querySelectorAll('video');
    if (VideoVol.length > 0) {
      VideoVol[0].volume = 0;
    }
  }, [participantStore.participants.length]);


  const onMode = () => {
    setCamDisabled(true);
    setMode(!mode);
    setMmode(mMode + 1);
  };

  const onCamDisabled = (flag: any) => {
    setCamDisabled(flag);
  };

  const [confirmOpen, setConfirmOpen] = useState(false);

  const listener = async (event: any) => {
    let data: any;
    try {
      data = JSON.parse(event.data);
    } catch (e) {
      console.log('e', event, e);
    }

    if (data.type === 'excuteMethod') {
      if (data.payload === 'leaveMeeting') {
        console.log('excuteMethod leaveMeeting', data);
        setConfirmOpen(true);
      }
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (window.ReactNativeWebView) {
      try {
        // android
        document.addEventListener('message', listener);
        // ios
        window.addEventListener('message', listener);
      } catch (e) {
        console.log(e);
      }
    }
    return () => {
      try {
        // android
        document.removeEventListener('message', listener);
        // ios
        window.removeEventListener('message', listener);
      } catch (e) {}
    };
  }, []);

  const onAlertFlag = (flag: boolean, message: any) => {
    props.returnAlertFlag(flag, message);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openLeave = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    props.ownerYn === 'Y' ? setAnchorEl(event.currentTarget) : props.leaveRoom();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
      setMobileChangeSwiper(Math.random());
      setSwiperSpeed(5);
  }, [props.mobileChange]);

  useEffect(() => {
    if (participantStore.participants.length > 1) {
      setType(true);
      setMobileFullWidth(props.mobileWidth / 4);
      setMobileFullScreen(props.mobileHight / 4);
      setMargin(2);
    } else {
      setType(false);
      setMobileFullWidth(props.mobileWidth);
      setMobileFullScreen(props.mobileHight + 7);
      setMargin(0);
    }
}, [props.mobileWidth]);
   

  useEffect(() => {
    if (mobileChangeSwiper !== 0) {
      swiper?.slideTo(props.videoFirstIndex - 1);
      setSwiperSpeed(300);
    }
  }, [mobileChangeSwiper]);

  return (
    <>
      <Stack sx={{ width: '100%', flex: 1, height: '100%' }}>
        <Paper elevation={4} sx={{ borderRadius: 0 }}>
          <Toolbar
            disableGutters
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              height: {
                xs: HEADER.H_MOBILE, //64px
                md: HEADER.H_MAIN_DESKTOP,
              },
            }}
          >
            <Stack
              display={'flex'}
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              sx={{ width: '100%', px: 2 }}
            >
              {props.mobile && (
                <IconButton
                  id={'camReversal'}
                  onClick={() => onMode()}
                  disabled={camDisabled}
                >
                  <Iconify icon={'ic:sharp-flip-camera-ios'} color={'#999999'} />
                </IconButton>
              )}
              {!props.mobile && <IconButton></IconButton>}
              <Stack
                spacing={1}
                display={'flex'}
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                sx={{
                  px: 2,
                  textAlign: 'center',
                  width: '80%',
                  cursor: 'pointer',
                }}
                onClick={() => setViewSummary(true)}
              >
                <Box width={'5%'}>
                  <IcoRoomInfo fill={theme.palette.primary.light} />
                </Box>
                <Stack width={'90%'}>
                  <Typography
                    variant={'h4'}
                    color={'#FFFFFF'}
                    sx={{
                      maxWidth: '100%',
                      textAlign: 'center',
                      cursor: 'pointer',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {props.data.title}
                  </Typography>
                  <MTime start={conferenceStore.conference.startedTime} />
                </Stack>
                <Box width={'5%'}>
                  <Iconify icon={'mingcute:down-line'} sx={{ mt: 1 }} />
                </Box>
              </Stack>
              <Tooltip title="">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={openLeave ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openLeave ? 'true' : undefined}
                >
                  <Logout fontSize="small" color="error" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Toolbar>
        </Paper>
        <Stack
          sx={{
            flex: 1,
            width: '100%',
            maxHeight: `calc(100% - ${HEADER.H_MOBILE * 2}px)`,
            overflow: 'hidden',
            '& .slick-dots': {
              position: 'fixed',
              bottom: `${HEADER.H_MOBILE + 20}px`,
              left: '50%',
              zIndex: 200,
              transform: 'translateX(-50%)',
              '& li button:before, li.slick-active button:before': {
                width: 8,
                height: 8,
                background: '#FFFFFF',
                borderRadius: '50%',
                color: '#FFFFFF',
                content: "''",
              },
            },
          }}
        >
          <Box sx={{ position: 'absolute', zIndex: 100, right: 0, margin: margin }}>
            {participantStore.participants.map((participant: IParticipant, i: number) => {
              if (participant.stream?.activities[0] !== 'SCREEN' && i === 0) {
                let videoView: any;
                const streamVideo = participant.stream?.activities.find((item) => {
                  return item === 'VIDEO';
                });
                const streamAudio = participant.stream?.activities.find((item) => {
                  return item === 'AUDIO';
                });
                if (streamVideo !== undefined) {
                  videoView = 'video';
                } else if (streamAudio !== undefined) {
                  videoView = 'audio';
                } else {
                  videoView = 'video';
                }
                return (
                  <MobileParticipant
                    key={i}
                    participant={participant}
                    sendMessage={props.sendMessage}
                    onConnectionstatechange={props.onConnectionstatechange}
                    setPeer={props.addPeer}
                    videostate={props.state}
                    ToggleMic={props.handleToggleMic}
                    Mestream={props.handStream}
                    ToggleVideo={props.handleToggleVideo}
                    videoWidth={mobileFullWidth}
                    myStream={props.stream}
                    videoHeight={mobileFullScreen}
                    videoView={videoView}
                    mobileYn={mode}
                    mMode={mMode}
                    detaildata={props.data}
                    onCamDisabled={onCamDisabled}
                    onButtonFlag={props.onButtonFlag}
                    returnAlertFlag={onAlertFlag}
                    videoStream={props.handVideoStream}
                    myStema={'Y'}
                  />
                );
              }
            })}
          </Box>
          <Box sx={{ width: props.MobileFullWidth , height: props.MobileFullScreen + 7 }}>
            <Swiper
              followFinger={false}
              className="mySwiper swiper-h"
              spaceBetween={50}
              pagination={{
                clickable: true,
              }}
              onSwiper={setSwiper}
              modules={[Pagination]}
              speed={swiperSpeed}
            >
              {participantStore.participants.map((participant: IParticipant, i: number) => {
                if (participant.stream?.activities[0] !== 'SCREEN' && i > 0) {
                  let videoView: any;
                  const streamVideo = participant.stream?.activities.find((item) => {
                    return item === 'VIDEO';
                  });
                  const streamAudio = participant.stream?.activities.find((item) => {
                    return item === 'AUDIO';
                  });
                  if (streamVideo !== undefined) {
                    videoView = 'video';
                  } else if (streamAudio !== undefined) {
                    videoView = 'audio';
                  } else {
                    videoView = 'video';
                  }
                  return (
                    <SwiperSlide key={i} style={{zIndex:999}}>
                      <MobileParticipant
                        participant={participant}
                        sendMessage={props.sendMessage}
                        onConnectionstatechange={props.onConnectionstatechange}
                        setPeer={props.addPeer}
                        videostate={props.state}
                        ToggleMic={props.handleToggleMic}
                        Mestream={props.handStream}
                        ToggleVideo={props.handleToggleVideo}
                        videoWidth={props.MobileFullWidth}
                        videoHeight={props.MobileFullScreen + 7}
                        videoView={videoView}
                        detaildata={props.data}
                        returnAlertFlag={onAlertFlag}
                        myStema={'N'}
                      />
                    </SwiperSlide>
                  );
                }
              })}
            </Swiper>
          </Box>
        </Stack>
        <Stack
          direction={'row'}
          justifyContent={isDownSm ? 'space-between' : 'center'}
          alignItems={'center'}
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            background: '#000000',
            whiteSpace: 'nowrap',
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            height: HEADER.H_MOBILE,
          }}
        >
          <Footer {...props.footerProps} />
        </Stack>
      </Stack>
      <Drawer
        open={viewSummary}
        onClose={() => {
          setViewSummary(false);
        }}
        PaperProps={{
          sx: {
            pb: 3,
            m: 'auto',
            width: '100%',
            maxWidth: 'md',
            borderRadius: 4,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        }}
        anchor={'bottom'}
      >
        <Stack
          sx={{
            p: 4,
            pt: 2,
            '& .MuiListItemText-root': {
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'flex-end',
              ph: 1,
            },
            '& .MuiListItemText-primary': {},
            '& .MuiListItemText-secondary': {
              minWidth: 100,
            },
          }}
        >
          <Typography
            variant={'h3'}
            color={'#FFFFFF'}
            sx={{
              py: 1,
              '&.MuiTypography-root': {
                wordBreak: 'break-word',
              },
            }}
          >
            {props.state.title}
            {/* <Typography variant="body2">호스트 : {props.state.owner.displayName}</Typography> */}
          </Typography>
          <ListItemText
            primary={props.state.id}
            secondary={translate('conferenceDetail.conferId').toString()}
          />
          {/* <ListItemText primary={props.state.owner.displayName} secondary={'호스트'} /> */}
          <ListItemText
            primary={
              translate(`type.${props.state.room.type}`).toString() ||
              translate('type.normal').toString()
            }
            secondary={translate('conferenceReservation.type').toString()}
          />
          <ListItemText
            primary={
              props.state.room.allowRecording === true
                ? translate('conferenceDetail.true').toString()
                : translate('conferenceDetail.false').toString()
            }
            secondary={translate('conferenceReservation.allowRecording').toString()}
          />
          <ListItemText
            primary={props.state.conference_date}
            secondary={translate('conferenceDetail.date').toString()}
          />

          <ListItemText
            primary={
              props.data.meetingMembers &&
              props.data.meetingMembers.map((v: IMeetingMemberSnapshot, i: number) => {
                const comma =
                  props.data.meetingMembers && i !== props.data.meetingMembers.length - 1
                    ? ','
                    : '';
                return (
                  <Fragment key={(v.id, i)}>
                    {v.invitationYn === 'N' ? (
                      <s>{v.user.displayName}</s>
                    ) : v.invitationYn === 'Y' ? (
                      <strong>{v.user.displayName}</strong>
                    ) : (
                      <span style={{ fontWeight: 'lighter' }}>{v.user.displayName}</span>
                    )}
                    {comma}
                    &nbsp;
                  </Fragment>
                );
              })
            }
            secondary={translate('meeting.participant').toString()}
          />

          {props.state.ownerYn === 'Y' && (
            <Button
              variant={'contained'}
              onClick={() => {
                setViewSummary(false);
                setOpenInvite(true);
              }}
              sx={{ mt: 2 }}
            >
              {translate('meeting.invite').toString()}
            </Button>
          )}
        </Stack>
      </Drawer>
      {props.footerProps.openSide && (
        <Drawer
          variant="permanent"
          anchor="bottom"
          open={props.footerProps.openSide}
          PaperProps={{
            style: {
              flex: 1,
              width: '100%',
              height: '100%',
              overflowX: 'hidden',
              flexShrink: 0,
            },
          }}
        >
          <Sidebar {...props.footerProps.sidebarProps} />
        </Drawer>
      )}
      {openInvite && (
        <Dialog
          open={openInvite}
          fullWidth
          keepMounted
          maxWidth={'md'}
          hideBackdrop
          PaperProps={{
            sx: {
              p: 4,
              borderRadius: 4,
            },
          }}
          onClose={() => {
            setOpenInvite(false);
          }}
        >
          <IconButton
            onClick={() => {
              setOpenInvite(false);
            }}
            sx={{ position: 'absolute', top: 15, right: 15, zIndex: 99 }}
          >
            <Iconify icon={'ic:round-close'} />
          </IconButton>
          <ConferenceInvite
            data={props.state}
            handleClose={() => setOpenInvite(false)}
            getDetail={props.getDetail}
          />
        </Dialog>
      )}
      {openLeave && (
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={openLeave}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={props.leaveRoom}>
            <Button
              variant={'outlined'}
              size={'small'}
              color={'error'}
              id={'mobileLeaveRoom'}
              sx={{ wordBreak: 'keep-all', lineHeight: 1, width: '100%' }}
            >
              {translate('waiting.leave').toString()}
            </Button>
          </MenuItem>

          {props.ownerYn === 'Y' && (
            <MenuItem onClick={props.onRoomClose}>
              <Button
                variant="contained"
                size={'small'}
                color={'error'}
                id={'mobileonRoomClose'}
                sx={{ wordBreak: 'keep-all', lineHeight: 1, width: '100%' }}
              >
                {translate(`waiting.end`).toString()}
              </Button>
            </MenuItem>
          )}
        </Menu>
      )}
      {confirmOpen && (
        <CAlert
          open={confirmOpen}
          content={translate(`meeting.alert.leaveAlert`).toString()}
          handleClose={() => setConfirmOpen(false)}
          hasCancel
          callBack={props.leaveRoom}
        />
      )}
    </>
  );
});

export default MeetingM;
